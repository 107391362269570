// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import StandardCarousel from '@bodhi-project/components/lib/carousel/standard/gatsby'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/carousel/style/css'
import '@bodhi-project/components/lib/carousel/standard/style.less'
import '@bodhi-project/components/lib/standard-renderers/section/vertical.less'

import Tabs from 'antd/lib/tabs'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/tabs/style/css'

import Division from '@bodhi-project/components/lib/Division'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/row/style/css'
import '@bodhi-project/antrd/lib/just-futura/3.19.3/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/Link'
import StandardPage from '../components/wrappers/StandardPage'
import seoHelper from '../methods/seoHelper'

import '../styles/pages/index.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React
const pageData = {
  pageTitle:
    'Leading school management services in India | Learning Wings Education System',
  nakedPageSlug: '',
  pageAbstract:
    'Learning Wings is an organization devoted to academic development through school management services, lifelong learning programmes and professional training programmes.',
}

const seoData = seoHelper(pageData)

const { TabPane } = Tabs

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
    slide1: file(relativePath: { eq: "slides/early-years-education.jpg" }) {
      ...max3000
    }
    slide2: file(relativePath: { eq: "slides/k12-education.jpg" }) {
      ...max3000
    }
    slide3: file(relativePath: { eq: "slides/lifelong-learning.jpg" }) {
      ...max3000
    }
    slide4: file(relativePath: { eq: "slides/schools-for-good.jpg" }) {
      ...max3000
    }
    people: file(relativePath: { eq: "team/team.jpg" }) {
      ...max3000
    }
    philosophy: file(relativePath: { eq: "philosophy.jpg" }) {
      ...max3000
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => {
  const { data } = props

  const carouselData = [
    {
      title: 'Cambridge Sparkling Stars',
      cover: props.data.slide1.childImageSharp.fluid,
      abstract:
        'Award winning educational programmes for children aged 18 months to 6 years.',
      route: '/early-education',
    },
    {
      title: 'Cambridge Internaional Schools',
      cover: props.data.slide2.childImageSharp.fluid,
      abstract:
        'K12 school spread across north India, delivering CBSE, IB or CIE curriculum.',
      route: '/k12-schools',
    },
    {
      title: 'Language Centers',
      cover: props.data.slide3.childImageSharp.fluid,
      abstract: 'Crafted language programmes for adult learner.',
      route: '/language-centers',
    },
    // {
    //   title: 'Schools for Good',
    //   cover: props.data.slide4.childImageSharp.fluid,
    //   abstract:
    //     'We are deeply involved in activities for the good of the community – 100% scholarships, community services and more.',
    //   route: '/schools-for-good',
    // },
  ]

  return (
    <StandardPage className="page homepage" seoData={seoData} {...props}>
      <div className="small-default-container" id="this">
        <StandardCarousel data={carouselData} Img={Img} Link={Link} />
        <br />
        <Tabs type="card">
          <TabPane
            tab={
              <p>
                <span>Early Education</span>
              </p>
            }
            key="1"
          >
            <p style={{ maxWidth: '40rem' }}>
              Cambridge Sparkling Stars is an exciting aspiration of Learning
              Wings with a mission that children are worthy of deepest respect.
              They are to be provided with opportunities to discover their
              abilities in every area of knowledge and most importantly, be
              given freedom to explore at their own pace and in their own way.
            </p>
            <p style={{ maxWidth: '40rem' }}>
              <Link to="/early-education">See more ⇝</Link>
            </p>
          </TabPane>
          <TabPane
            tab={
              <p>
                <span>K12 Schools</span>
              </p>
            }
            key="2"
          >
            <p style={{ maxWidth: '40rem' }}>
              Learning Wings Education Systems has has K1-12 school spread
              across north India, delivering CBSE, IB or CIE curriculum. At our
              schools the goal is to nurture the dreams of tender hearts. Every
              child is equipped with the confidence to pursue the dreams and
              realize them. In this world of turmoil and unrest they learn to
              pave their own path to success.
            </p>
            <p style={{ maxWidth: '40rem' }}>
              <Link to="/k12-schools">See more ⇝</Link>
            </p>
          </TabPane>
          <TabPane
            tab={
              <p>
                <span>Language Centers</span>
              </p>
            }
            key="3"
          >
            <p style={{ maxWidth: '40rem' }}>
              Learning Wings Education Systems is the only authorized centre
              (IN364) in Punjab for conducting Qualifications for Cambridge
              Assessment English, a department of the University of Cambridge
              and part of Cambridge Assessment Group, which provides world’s
              leading range of qualifications for learners and teachers for
              English. We are empowered to facilitate English Language training
              programs in all its developmental learning centres throughout
              India.
            </p>
            <p style={{ maxWidth: '40rem' }}>
              <Link to="/language-centers">See more ⇝</Link>
            </p>
          </TabPane>
          <TabPane
            tab={
              <p>
                <span>Lifelong Learning Centers</span>
              </p>
            }
            key="4"
          >
            <p style={{ maxWidth: '40rem' }}>
              Each individual is a learner in a way or another at each phase of
              life, in support to this statement, Cambridge Sparkling Stars’
              learning centre presents development opportunities for people of
              all age groups starting, six months’ child to eighty years old
              person. It contributes to the Physical, Emotional, Cognitive and
              Social development of the Learners. A passionate team of expert
              professionals at our learning centres works for producing
              best-in-class learning programmes to pioneer learning through
              emotional, physical, creative and intellectual growth of its
              learners.
            </p>
            <p style={{ maxWidth: '40rem' }}>
              <Link to="/adult-learning">See more ⇝</Link>
            </p>
          </TabPane>
          <TabPane
            tab={
              <p>
                <span>Teachers Traning Centers</span>
              </p>
            }
            key="5"
          >
            <p style={{ maxWidth: '40rem' }}>
              Learning Wings Education Systems endorses and believes in giving
              wings to its teachers to soar high and makes sure that the wings
              are strengthened from time to time by providing them with
              professional development so that the Cambridge Team stands apart
              from rest of the schools in the region.
            </p>
            <p style={{ maxWidth: '40rem' }}>
              <Link to="/teachers-education">See more ⇝</Link>
            </p>
          </TabPane>
        </Tabs>
        <br />
        <Division breakpoint={768}>
          <Fragment>
            <h2 className="mask-h4">People</h2>
            <p style={{ maxWidth: '40rem' }}>
              Our biggest strengths are our people who are devoted to education.
              If we have been able to impact thousands of students in the last
              20 years, it is only because of the people who have made it happen
              through their dedication and expertise. We are fortunate to have
              some of the brightest minds of both the corporate and the
              nonprofit world working towards empowering and changing the lives
              of children.
            </p>
            <p style={{ maxWidth: '40rem' }}>
              <Link to="/team">Our team ⇝</Link>
            </p>
          </Fragment>
          <div className="people">
            <Img fluid={props.data.people.childImageSharp.fluid} />
          </div>
        </Division>
        <br />
        <div className="philosophy">
          <Link
            className="ignore"
            to="/philosophy"
            style={{ position: 'relative' }}
          >
            <Img fluid={props.data.philosophy.childImageSharp.fluid} />
            <div
              style={{ width: 400, position: 'absolute', top: 20, left: 20 }}
            >
              <h2 className="mask-h4">Philosophy</h2>
              <p>
                Our mission is to nurture the development of responsible,
                thoughtful citizens for life in an increasingly interdependent
                global society by creating environments in which students are
                challenged to explore, to create and to make decisions. Read
                more ⇝
              </p>
            </div>
          </Link>
        </div>
      </div>
    </StandardPage>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
